import { Card } from "antd";
import PropTypes from "prop-types";
import React from "react";
import classes from "./Panel.module.scss";

const Panel = props => {
  const { children, header, footer, style, className } = props;

  return (
    <Card
      className={`${classes.panel} ${className}`}
      style={{ overflow: "hidden", ...style }}
    >
      <div className={classes.header}>{header}</div>
      <div className={classes.content}>{children}</div>
      {footer && <div className={classes.footer}>{footer}</div>}
    </Card>
  );
};

export default Panel;

Panel.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  footer: PropTypes.node,
  style: PropTypes.object,
  className: PropTypes.string,
};
