import EP from "consts/api";
import api from "modules/api";

export default class CustomerSegments {
  static getSuitCustomerSegments = (serv, appid, master) => {
    return api("get", EP.SEGMENTS.ALL(serv), false, false, false)
      .set("Content-Type", "application/json")
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };
  static createNewSegment = (data, serv, appid, master) => {
    return api("post", EP.SEGMENTS.ALL(serv), data, false, false)
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`)
      .set("Content-Type", "application/json");
  };
  static deleteSegment = (serv, appid, master, SegmentId) => {
    return api("delete", EP.SEGMENTS.SINGLE(serv, SegmentId))
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static updateSegment = (serv, appid, master, data, segmentId) => {
    return api("put", EP.SEGMENTS.SINGLE(serv, segmentId), data, false, false)
      .set("x-parse-application-id", `${appid}`)
      .set("x-parse-master-key", `${master}`);
  };

  static addUsersToSegment = (appBackend, body) => {
    return api("post", EP.SEGMENTS.CLOUD(appBackend.serverURL), body)
      .set("x-parse-application-id", `${appBackend.appId}`)
      .set("x-parse-master-key", `${appBackend.masterKey}`);
  };

  static forceUpdateSegments = (suitId) =>
    api("PUT", EP.SEGMENTS.FORCE_UPDATE(suitId));
}
