import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { GoQuestion } from "react-icons/go";
import { I18n } from "react-redux-i18n";
import classes from "./Tooltip2.module.scss";

const Tooltip2 = props => {
  const { description } = props;
  return (
    <Tooltip title={I18n.t(description)}>
      <GoQuestion className={classes.icon} />
    </Tooltip>
  );
};

export default Tooltip2;

Tooltip2.propTypes = {
  description: PropTypes.string,
};
