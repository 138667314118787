import { Tooltip } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { I18n } from "react-redux-i18n";
import classes from "./Label.module.scss";

const Label = props => {
  const { children, id, style, helper } = props;

  return (
    <label className={classes.label} htmlFor={id} style={style}>
      {children}
      {helper && (
        <Tooltip title={I18n.t(helper)}>
          <IoMdInformationCircleOutline className={classes.icon} />
        </Tooltip>
      )}
    </label>
  );
};

export default Label;

Label.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  style: PropTypes.object,
  helper: PropTypes.string,
};
