import { Button, Tooltip } from "antd";
import { Col, CustomSelector, Form, Label, Row } from "components";
import TextBox from "components/Form/TextBox/TextBox";
import timezoneOptions from "consts/timezoneOptions";
import $ from "jquery";
import _ from "lodash";
import { endofspicialChar, hasWhiteSpaces } from "modules/validations";
import React from "react";
import DateTime from "react-datetime";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { I18n } from "react-redux-i18n";
import Select from "react-select";
import { Field, change as ReduxChange, reduxForm } from "redux-form";
import classes from "../CreateAppPush.scss";

const customStyles = {
  menu: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
  menuList: (provided) => ({
    ...provided,
    zIndex: 9999,
  }),
};

const targetOptions = [
  { value: "noTarget", label: I18n.t("allusersAndDevices") },
  {
    value: "deviceType",
    label: I18n.t("deviceType"),
  },
  {
    value: "segments",
    label: I18n.t("Segments"),
  },
  {
    value: "userId",
    label: I18n.t("UserId"),
  },
];

class PushPublishStep extends React.PureComponent {
  componentWillMount() {
    const { targetType, deviceType } = this.props.config.action.appPush;

    const params = new URLSearchParams(window.location.search);
    let userIdVal = params.get("userId");
    if (userIdVal) {
      this.props.initialize({ targetTypeUserId: userIdVal });
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.appPush.targetType", "userId")
      );
    }

    if (!this.props.pushPublishStep.values.action.targetType) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.appPush.targetType", "noTarget")
      );
    }
    const { frequency, sendNow, repeat, time } = this.props;
    this.props.dispatch(
      ReduxChange("pushPublishStep", "action.frequency", frequency)
    );
    this.props.dispatch(
      ReduxChange("pushPublishStep", "action.sendStatus", sendNow)
    );
    this.props.dispatch(
      ReduxChange("pushPublishStep", "action.repeatStatus", repeat)
    );
    this.props.dispatch(
      ReduxChange("pushPublishStep", "action.repeatEndOn", time.end)
    );
    this.props.dispatch(
      ReduxChange("pushPublishStep", "action.repeatStartOn", time.start)
    );
    this.props.dispatch(
      ReduxChange("pushPublishStep", "action.timezone", time.timezone)
    );

    if (targetType) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.appPush.targetType", targetType)
      );
      this.props.handleSelectTarget(targetType, targetType);

      if (deviceType) {
        this.props.dispatch(
          ReduxChange(
            "pushPublishStep",
            "action.appPush.deviceType",
            deviceType
          )
        );
        if (deviceType === "ios") {
          this.props.handleDeviceSelect("IOS", deviceType);
        } else {
          this.props.handleDeviceSelect("Android", deviceType);
        }
      }
    }
    if (userIdVal) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.appPush.targetType", "userId")
      );
      this.props.handleSelectTarget("User Id", "userId");
      this.props.dispatch(
        ReduxChange(
          "pushPublishStep",
          "action.appPush.targetTypeUserId",
          userIdVal
        )
      );
    }
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).hide().fadeIn(250);

    // set default id
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get("userId");

    if (userId) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.appPush.targetUserIds", userId)
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { frequency, sendNow, repeat, time } = this.props;
    if (prevProps.frequency !== frequency) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.frequency", frequency)
      );
    }
    if (prevProps.sendNow !== sendNow) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.sendStatus", sendNow)
      );
    }
    if (prevProps.repeat !== repeat) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.repeatStatus", repeat)
      );
    }
    if (prevProps.time.end !== time.end) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.repeatEndOn", time.end)
      );
    }
    if (prevProps.time.start !== time.start) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.repeatStartOn", time.start)
      );
    }
    if (prevProps.time.timezone !== time.timezone) {
      this.props.dispatch(
        ReduxChange("pushPublishStep", "action.timezone", time.timezone)
      );
    }
  }

  render() {
    const {
      handleChange,
      type = "appPush",
      config,
      targetType,
      selectedDevice,
      handleSelectTarget,
      handleDeviceSelect,
      toggleSendNow,
      sendNow,
      toggleRepeat,
      handleSegmentSelect,
      selectedSegment,
      handleStartTimeChange,
      segments,
      repeat,
      handleEndTimeChange,
      frequency,
      handleFrequencyChange,
      handleTimeZoneChange,
      timeObj,
      refetchSegments,
      segmentsLoading,
      time,
    } = this.props;

    /**
     * noTarget
     * deviceType
     * segments
     * userId
     */

    const segmentOptions = segments
      ? segments.map((segment) => ({
          label: segment.name,
          value: segment.objectId,
        }))
      : [];

    return (
      <Form>
        <Row>
          <Col xs={targetType.value === "noTarget" ? 12 : 5}>
            <div>
              <Label className={classes["my-form-label"]}>
                {I18n.t("sendto")}
              </Label>
              <Select
                styles={customStyles}
                className={classes["select-style"]}
                value={targetType}
                onChange={(selectedCategory) => {
                  const selectedType = selectedCategory.value;
                  const targetValue =
                    selectedType === "noTarget" || selectedType === "userId"
                      ? ""
                      : selectedType === "deviceType"
                        ? selectedDevice.value
                        : selectedType === "segments"
                          ? _.get(segments, "[0].name")
                          : null;
                  const name =
                    selectedType === "noTarget" || selectedType === "userId"
                      ? ""
                      : selectedType === "deviceType"
                        ? selectedDevice.label
                        : selectedType === "segments"
                          ? _.get(segments, "[0].name")
                          : null;

                  const { label, value } = selectedCategory;

                  handleSelectTarget(label, value);
                  if (targetValue === "android") {
                    handleDeviceSelect(name, targetValue);
                  }

                  this.props.dispatch(
                    ReduxChange(
                      "pushPublishStep",
                      `action.${type}.targetType`,
                      value
                    )
                  );

                  this.props.dispatch(
                    ReduxChange(
                      "pushPublishStep",
                      value === "deviceType"
                        ? `action.appPush.targetTypeDevice`
                        : value === "segments"
                          ? "action.appPush.segmentValue"
                          : "",
                      targetValue
                    )
                  );
                }}
                options={targetOptions}
              />
            </div>
          </Col>

          {targetType.value !== "noTarget" && (
            <Col xs={7}>
              <div className={classes["publish-fields"]}>
                {targetType.value === "userId" ? (
                  <div>
                    <Label className={classes["my-form-label"]}>
                      {I18n.t("UserId")}
                    </Label>
                    <Field
                      name="action.appPush.targetUserIds"
                      placeholder={I18n.t("UserId_placeholder")}
                      validate={[hasWhiteSpaces, endofspicialChar]}
                      component={TextBox}
                      value={config.action.appPush.targetUserIds}
                      rightBottomHint={I18n.t("UserID_hint")}
                      onChange={(e, value) => {
                        handleChange(
                          "action.appPush.targetUserIds",
                          value.split(",").map((userid) => ({ userID: userid }))
                        );
                      }}
                    />
                  </div>
                ) : targetType.value === "deviceType" ? (
                  <div>
                    <Label className={classes["my-form-label"]}>
                      {I18n.t("deviceType")}
                    </Label>
                    <Select
                      styles={customStyles}
                      className={classes["select-style"]}
                      value={selectedDevice}
                      onChange={(selectedDevice) => {
                        const { label, value } = selectedDevice;
                        handleDeviceSelect(label, value);
                        this.props.dispatch(
                          ReduxChange(
                            "pushPublishStep",
                            `action.${type}.targetTypeDevice`,
                            selectedDevice.value
                          )
                        );
                      }}
                      options={[
                        {
                          label: I18n.t("Android"),
                          value: "android",
                        },
                        {
                          label: I18n.t("IOS"),
                          value: "ios",
                        },
                      ].map(({ label, value }) => ({
                        label,
                        value,
                      }))}
                    />
                  </div>
                ) : targetType.value === "segments" ? (
                  <div>
                    <Label className={classes["my-form-label"]}>
                      {I18n.t("SelectSegment")}
                    </Label>
                    <div className={classes.segmentSelectorContainer}>
                      <CustomSelector
                        options={segmentOptions}
                        value={selectedSegment}
                        onChange={(newValue) => {
                          handleSegmentSelect(newValue);
                          this.props.dispatch(
                            ReduxChange(
                              "pushPublishStep",
                              "action.appPush.segmentId",
                              newValue.value
                            )
                          );
                        }}
                        loading={segmentsLoading}
                      />
                      <Tooltip title="Refresh Segments">
                        <Button
                          shape="circle"
                          icon="reload"
                          onClick={refetchSegments}
                          loading={segmentsLoading}
                        />
                      </Tooltip>
                    </div>
                  </div>
                ) : null}
              </div>
            </Col>
          )}
        </Row>

        <div
          style={{ marginTop: "1rem" }}
          className={classes["publish-fields"]}
        >
          <Label className={classes["my-form-label"]}>
            {I18n.t("whenToSend")}
          </Label>
          <Select
            styles={customStyles}
            className={classes["select-style"]}
            value={
              sendNow
                ? {
                    label: I18n.t("Sendnow"),
                    value: "sendNow",
                  }
                : {
                    label: I18n.t("Sendlater"),
                    value: "sendLater",
                  }
            }
            options={[
              {
                label: I18n.t("Sendnow"),
                value: "sendNow",
              },
              {
                label: I18n.t("Sendlater"),
                value: "sendLater",
              },
            ]}
            onChange={(selected) => {
              if (
                (sendNow && selected.value === "sendNow") ||
                (!sendNow && selected.value === "sendLater")
              ) {
                return;
              }
              toggleSendNow();
            }}
          />
        </div>

        {!sendNow && (
          <div style={{ marginTop: "1rem" }}>
            <div className="form-row form-margin">
              <Label className={classes["my-form-label"]}>
                {I18n.t("Repeat")}
              </Label>
              <Select
                styles={customStyles}
                className={classes["select-style"]}
                value={
                  repeat
                    ? {
                        label: I18n.t("Repeat"),
                        value: "repeat",
                      }
                    : {
                        label: I18n.t("oneTime"),
                        value: "oneTime",
                      }
                }
                options={[
                  {
                    label: I18n.t("oneTime"),
                    value: "oneTime",
                  },
                  {
                    label: I18n.t("Repeat"),
                    value: "repeat",
                  },
                ]}
                onChange={(selected) => {
                  if (
                    (repeat && selected.value === "repeat") ||
                    (!repeat && selected.value === "oneTime")
                  ) {
                    return;
                  }
                  toggleRepeat();
                }}
              />

              <Row style={{ marginTop: "1rem" }}>
                <Col xs={repeat ? 4 : 6}>
                  <div className="form-row form-margin">
                    <Label className={classes["my-form-label"]}>
                      {repeat ? I18n.t("Starton") : I18n.t("when")}
                    </Label>
                    <div
                      className={`${classes["modified-datepicker-container"]} ${classes["datepicker-container"]}`}
                    >
                      <DateTime
                        isValidDate={(currentDate) => {
                          return currentDate.isAfter(
                            DateTime.moment().subtract(1, "day")
                          );
                        }}
                        value={timeObj.start}
                        onChange={handleStartTimeChange}
                      />
                    </div>
                  </div>
                </Col>
                {repeat && (
                  <Col xs={4}>
                    <div className="form-row form-margin">
                      <Label className={classes["my-form-label"]}>
                        {I18n.t("Endon")}
                      </Label>
                      <div
                        className={`${classes["modified-datepicker-container"]} ${classes["datepicker-container"]}`}
                      >
                        <DateTime
                          isValidDate={(currentDate) => {
                            return currentDate.isAfter(
                              DateTime.moment().subtract(1, "day")
                            );
                          }}
                          value={timeObj.end}
                          onChange={handleEndTimeChange}
                        />
                      </div>
                    </div>
                  </Col>
                )}
                <Col xs={repeat ? 4 : 6}>
                  <Label className={classes["my-form-label"]}>
                    {I18n.t("TimeZone")}
                  </Label>
                  <CustomSelector
                    options={timezoneOptions}
                    value={time.timezone}
                    onChange={handleTimeZoneChange}
                  />
                </Col>
              </Row>
              {repeat && !sendNow && (
                <Row>
                  <Col xs={repeat ? 4 : 6}>
                    <Label className={classes["my-form-label"]}>
                      {I18n.t("Sendevery")}
                    </Label>
                    <Select
                      styles={customStyles}
                      className={classes["select-style"]}
                      value={
                        frequency === "day"
                          ? {
                              label: I18n.t("day"),
                              value: "day",
                            }
                          : frequency === "week"
                            ? {
                                label: I18n.t("week"),
                                value: "week",
                              }
                            : null
                      }
                      options={[
                        {
                          label: I18n.t("day"),
                          value: "day",
                        },
                        {
                          label: I18n.t("week"),
                          value: "week",
                        },
                      ]}
                      onChange={(selected) => {
                        handleFrequencyChange(selected.value);
                      }}
                    />
                  </Col>
                </Row>
              )}
            </div>
            {repeat &&
              this.props.time &&
              this.props.time.start &&
              this.props.time.end &&
              new Date(this.props.time.start) >
                new Date(this.props.time.end) && (
                <div style={{ color: "red" }}>{I18n.t("dateselectHint")}</div>
              )}
          </div>
        )}
      </Form>
    );
  }
}

export default reduxForm({
  form: "pushPublishStep",
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: false,
})(
  connect((store) => ({
    pushPublishStep: store.form.pushPublishStep,
    segmentsLoading: store.CustomerSegment.isPending,
  }))(PushPublishStep)
);
